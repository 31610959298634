import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Columns, Container, Heading, Section, Element } from 'react-bulma-components'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'

const ThankYouPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>

      <Section size='medium'>
        <Container>
          <Columns centered gap={8} className='has-border-right'>
            <Columns.Column size={6}>
              <Heading size={5} textColor='dark' display='relative'>
                <Element renderAs='span' textColor='success' style={{ position: 'absolute', left: '-1.25em' }}>
                  ✓&nbsp;
                </Element>
                {t('user-pro-form:confirmation.title')}
              </Heading>
              <p>
                <Trans i18nKey='user-pro-form.confirmation.description'/>
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Section >
    </Layout >
  )
}

export default ThankYouPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`